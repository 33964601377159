import React, { useEffect } from "react";

const UmtTags = () => {
  useEffect(() => {
    sessionStorage.setItem("umt", window.location.search);
  }, []);

  return;
};

export default UmtTags;
