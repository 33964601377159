import { combineReducers, configureStore } from "@reduxjs/toolkit";
import appReducer from "./appReducer";

const reducer = combineReducers({
  appReducer: appReducer.reducer,
});

const store = configureStore({ reducer });

export default store;
