import { createSlice /* current */ } from "@reduxjs/toolkit";

import ALB from "../assets/images/logos/logo-alfredo-black.png";
import ALW from "../assets/images/logos/logo-alfredo-white.png";
import PLB from "../assets/images/logos/logo-propdata-black.png";
import PLW from "../assets/images/logos/logo-propdata-white.png";

export const appReducerStoredKeys = ["lang", "mode"];

const DASHBOARD_URLS = {
  development: {
    pt: "https://dashboard.sys.alfredo.pt",
    uk: "https://dashboard.sys.propdata.uk",
    es: "https://dashboard.sys.propdata.es",
  },
  production: {
    pt: "https://dashboard.alfredo.pt",
    uk: "https://dashboard.propdata.uk",
    es: "https://dashboard.propdata.es",
  },
};

const WEBSITE_URLS = {
  development: {
    pt: "https://playground.sys.alfredo.pt",
    uk: "https://sys.propdata.uk",
    es: "https://sys.propdata.es",
  },
  production: {
    pt: "https://www.alfredo.pt",
    uk: "https://www.propdata.uk",
    es: "https://www.propdata.es",
  },
};

const WEBSITE_DEMO_URLS = {
  pt: "https://johndoe.agent.alfredo.pt?#demo",
  uk: "https://johndoe.agent.alfredo.pt?#demo",
  es: "https://johndoe.agent.alfredo.pt?#demo",
};

const REPORT_URLS = {
  development: {
    pt: "https://report-iframe.dev.sys.alfredo.pt",
    uk: "https://report-iframe.dev.sys.propdata.uk",
    es: "https://report-iframe.dev.sys.propdata.es",
  },
  production: {
    pt: "https://report-iframe.sys.alfredo.pt",
    uk: "https://report-iframe.sys.propdata.uk",
    es: "https://report-iframe.sys.propdata.es",
  },
};

const ASSET_URLS = {
  development: {
    pt: "https://prospect-iframe.dev.sys.alfredo.pt",
    uk: "https://prospect-iframe.dev.sys.propdata.uk",
    es: "https://prospect-iframe.dev.sys.propdata.es",
  },
  production: {
    pt: "https://prospect-iframe.sys.alfredo.pt",
    uk: "https://prospect-iframe.sys.propdata.uk",
    es: "https://prospect-iframe.sys.propdata.es",
  },
};

/* const SUBSCRIBE_URLS = {
  pt: "register?redirect=professional&utm_source=alfredo&utm_medium=website&utm_campaign=subscription",
  uk: "register?redirect=professional&utm_source=alfredo&utm_medium=website&utm_campaign=subscription",
  es: "register?redirect=professional&utm_source=alfredo&utm_medium=website&utm_campaign=subscription",
}; */

const LANG = {
  pt: "pt",
  uk: "en",
  es: "es",
};

const COUNTRY = {
  pt: { pt: "Portugal", es: "Portugal", en: "Portugal" },
  es: { pt: "Espanha", es: "España", en: "Spain" },
  uk: { pt: "Grã-Bretanha", es: "Gran Bretaña", en: "Great Britain" },
};

const COMPANY = {
  pt: "Alfredo AI",
  uk: "PropData",
  es: "PropData",
};

const EMAIL = {
  pt: "geral@alfredo.pt",
  uk: "contact@propdata.uk",
  es: "contacto@propdata.es",
};

const BASE_URL =
  DASHBOARD_URLS[process.env.GATSBY_ACTIVE_ENV][process.env.GATSBY_ORIGIN];

const LOGOS = {
  pt: {
    light: ALB,
    dark: ALW,
  },
  es: {
    light: PLB,
    dark: PLW,
  },
  uk: {
    light: PLB,
    dark: PLW,
  },
};

const SOCIAL_MEDIA_HANDLERS = {
  pt: {
    facebook: "https://www.facebook.com/alfredoAI/",
    instagram: "https://www.instagram.com/alfredo.analytics/",
    linkedin: "https://www.linkedin.com/company/alfredo-ai-analytics/",
  },
  es: {
    facebook: "https://www.facebook.com/propdata.ai/",
    instagram: "https://www.instagram.com/propdata.ai/",
    linkedin: "https://www.linkedin.com/company/propdata-ai/",
  },
  uk: {
    facebook: "https://www.facebook.com/propdata.ai/",
    instagram: "https://www.instagram.com/propdata.ai/",
    linkedin: "https://www.linkedin.com/company/propdata-ai/",
  },
};

export const appReducerInitialState = {
  lang: LANG[process.env.GATSBY_ORIGIN],
  origin: process.env.GATSBY_ORIGIN,
  country: COUNTRY.pt,
  WEBSITE_URL:
    WEBSITE_URLS[process.env.GATSBY_ACTIVE_ENV][process.env.GATSBY_ORIGIN],
  SOCIAL_MEDIA_HANDLERS: SOCIAL_MEDIA_HANDLERS[process.env.GATSBY_ORIGIN],
  DASHBOARD_URL: BASE_URL,
  REGISTER_URL: `${BASE_URL}/register`,
  LOGIN_URL: `${BASE_URL}/login`,
  WEBSITE_DEMO_URL: WEBSITE_DEMO_URLS[process.env.GATSBY_ORIGIN],
  //SUBSCRIBE_URL: SUBSCRIBE_URLS[process.env.GATSBY_ORIGIN],
  SUBSCRIBE_UTM:
    "utm_source=alfredo&utm_medium=website&utm_campaign=subscription",
  COMPANY_NAME: COMPANY[process.env.GATSBY_ORIGIN],
  COMPANY_EMAIL: EMAIL[process.env.GATSBY_ORIGIN],
  REPORT_IFRAME_URL:
    REPORT_URLS[process.env.GATSBY_ACTIVE_ENV][process.env.GATSBY_ORIGIN],
  ASSET_IFRAME_URL:
    ASSET_URLS[process.env.GATSBY_ACTIVE_ENV][process.env.GATSBY_ORIGIN],
  openContact: false,
  LOGO: LOGOS[process.env.GATSBY_ORIGIN],
  mode: "light",
};

const appReducer = createSlice({
  name: "appReducer",
  initialState: appReducerInitialState,
  reducers: {
    triggerContact: (state) => {
      state.openContact = !state.openContact;
    },
    setLang: (state, action) => {
      state.lang = action.payload;
    },
    setKeyValue: (state, action) => {
      return {
        ...state,
        [action.payload.key]: action.payload.value,
        ...action.payload.object,
      };
    },
    setMode: (state, action) => {
      localStorage.setItem("mode", action.payload);
      return { ...state, mode: action.payload };
    },
  },
});

export const { setLang, setKeyValue, triggerContact, setMode } =
  appReducer.actions;

export default appReducer;

/*
const languages_dict = {
    pt: "pt",
    uk: "en",
    es: "es",
  }
  
const appReducer = createSlice({
  setLang: (state, action) => {
    localStorage.setItem("gatsby-lang", action.payload)
    return { ...state, lang: action.payload }
  },
}
*/
