export const LANG_SWITCHER = {
  pt: {
    pt: "Português (PT)",
    en: "Inglês (UK)",
    es: "Español (ES)",
  },
  en: {
    pt: "Portuguese (PT)",
    en: "English (UK)",
    es: "Español (ES)",
  },
  es: {
    pt: "Português (PT)",
    en: "Inglês (UK)",
    es: "Español (ES)",
  },
};

export const PERIODS = {
  pt: {
    month: "Mensal",
    semester: "Semestral",
    year: "Anual",
  },
  en: {
    month: "Monthly",
    semester: "Twice a year",
    year: "Yearly",
  },
  es: {
    month: "Mensual",
    semester: "Semestral",
    year: "Anual",
  },
};

export const CONTACT_MODULE = {
  pt: {
    title: "Enviar mensagem",
    subTitle:
      "Para enviar uma mensagem, por favor introduza o seu email. Responderemos assim que possível.",
    submit: "Submeter",
    name: "Nome",
    company: "Empresa",
    email: "Email",
    message: "Mensagem",
    required: "Campo obrigatório",
    invalidEmail: "Endereço de email inválido.",
    success: "Entraremos em contacto brevemente",
    fail: "Ocorreu um erro, por favor tente mais tarde",
    thanks: "Obrigado",
  },
  en: {
    title: "Send message",
    subTitle:
      "To send a message, please enter your email address here. We will answer shortly.",
    submit: "Submit",
    name: "Name",
    company: "Company",
    email: "Email",
    message: "Message",
    required: "Mandatory field",
    invalidEmail: "Invalid email.",
    success: "We will get back to you shortly",
    fail: "An error has occurred, please try again later",
    thanks: "Thanks",
  },
  es: {
    title: "Enviar mensaje",
    subTitle:
      "Para enviar un mensaje, por favor introduzca su email. Responderemos lo antes posible.",
    submit: "Submeter",
    name: "Nombre",
    company: "Empresa",
    email: "Email",
    message: "Mensaje",
    required: "Campo obligatório",
    invalidEmail: "Email inválido.",
    success: "Entraremos en contacto brevemente",
    fail: "Ocurrió un error, por favor inténtelo más tarde ",
    thanks: "Gracias",
  },
};

export const BANNER_SECTION = {
  pt: {
    try: "Experimentar",
    subscribe: "Subscrever",
    freeTrial: "Experimente Grátis",
    contactUs: "Fale connosco",
    exploreReports: "Explore relatórios de avaliação",
    trialSection: {
      title: "Experimente",
      subTitle: "Teremos todo o prazer de ajudá-lo no processo",
    },
    testimonialsSection: {
      header: "Testemunhos",
      title: "Conheça os que estão na frente",
      subTitle: "Clientes que usam as nossas soluções nos seus negócios",
    },
  },
  en: {
    try: "Try",
    subscribe: "Subscribe",
    freeTrial: "Try it for FREE",
    contactUs: "Get in touch",
    exploreReports: "Explore valuation reports",
    trialSection: {
      title: "Try it",
      subTitle: "We'll gladly walk you through it",
    },
    testimonialsSection: {
      header: "Testimonials",
      title: "Meet some trailblazers",
      subTitle: "Clients already using our solution",
    },
  },
  es: {
    try: "Experimentar",
    subscribe: "Suscribir",
    freeTrial: "Pruébalo Gratis",
    contactUs: "Contáctenos",
    exploreReports: "Explorar informes de valoración",
    trialSection: {
      title: "¡Vaya a por ello!",
      subTitle: "Estaremos encantados de ayudarle en el proceso.",
    },
    testimonialsSection: {
      header: "Testimonios",
      title: "Conozca a los que están al frente",
      subTitle: "Clientes que ya usan nuestra solución",
    },
  },
};

export const FEATURES = {
  pt: {
    t1: "Essencial",
    t2: "Soluções inovadoras potenciadas por Inteligência Artificial",
    t3: "Os nossos produtos, a sua experiência",
    sectionOne: {
      cardOne: {
        title: "Estudos de mercado, sem complicações",
        description:
          "Crie e partilhe relatórios com um click, alimentados por uma base de dados de 40m+ propriedades",
        action: "Ver estudos de mercado",
      },
      cardTwo: {
        title: "Identifique imóveis for-sale-by-owner",
        description:
          "Poupe tempo e trabalho a identificar novas oportunidades de negócio com o nosso módulo de metasearch",
        action: "Ver metasearch",
      },
      cardThree: {
        title: "Analise tendências de mercado em tempo real",
        description:
          "Tome decisões informadas tendo acesso a estatístas e indicadores únicos sobre o mercado",
        action: "Ver insights",
      },
      cardFour: {
        title: "Crie um website em 60 segundos",
        description:
          "Fortaleça a sua presença digital e leve o seu negócio para o próximo nível",
        action: "Ver website",
      },
      cardFive: {
        title: "Maximize a conversão de novas leads",
        description:
          "Posicione-se à frente da sua concorrência ao identificar novas oportunidades de negócio",
        action: "Ver avaliador online",
      },
    },
    sectionTwo: {
      t1: "Soluções de automação",
      t2: "Avalie e analise os seus imóveis",
      t3: "Crie experiências únicas dentro do mercado imobiliário",
      cardOne: {
        title: "Analise milhares de ativos por carteira",
        description:
          "Para investidores institucionais que queiram otimizar a performance do seu portfolio através de monitorização e análises detalhadas",
        action: "Ver portfolio",
      },
      cardTwo: {
        title: "Minimize o risco, monitorize tudo",
        action: "Ver soluções de risco",
        description:
          "Analise o seu portfolio de maneira a poder tomar melhores decisões de gestão",
      },
      cardThree: {
        title: "Descubra novas oportunidades",
        action: "Ver como",
        description:
          "Identifique onde estão os imóveis com melhor retorno sobre investimento",
      },
      cardFour: {
        title: "Precisa de um AVM customizado?",
        action: "Ver AVMs feitos à medida",
        description:
          "Combine a nossa tecnologia de AVM com os seus dados para criar modelos avançados",
      },
      cardFive: {
        title: "Deixe os seus dados contar a sua história",
        action: "Ver observatório",
        description:
          "Uma ferramenta totalmente customizável à narrativa que prende comunicar",
      },
    },
  },
  en: {
    t1: "Solutions",
    t2: "Innovation powered by Artificial Intelligence",
    t3: "Our product, your experience",
    sectionOne: {
      cardOne: {
        title: "Valuation reports, hassle-free",
        description:
          "Create and share reports effortlessly, powered by an historical database of 40m+ properties",
        action: "View market reports",
      },
      cardTwo: {
        title: "Find for-sale-by-owner opportunities",
        description:
          "Save time and effort identifying new business opportunities with our metasearch module",
        action: "View metasearch",
      },
      cardThree: {
        title: "Access unique market trends in real time",
        description:
          "Make informed decisions by accessing accurate and up-to-date market data and analytics",
        action: "View insights",
      },
      cardFour: {
        title: "Create a website in 60 seconds",
        description:
          "Strengthen your online presence and grow your business to new heights",
        action: "View website",
      },
      cardFive: {
        title: "Maximize lead conversion",
        description:
          "Get ahead of your competition by identifying new opportunities before them",
        action: "View digital valuer",
      },
    },
    sectionTwo: {
      t1: "Automation solutions",
      t2: "Value and analyse your assets",
      t3: "Our products enable you to create custom experiences in the real estate market",
      cardOne: {
        title: "Analyse thousands of assets per portfolio",
        description:
          "For institutional investors who want to optimize portfolio performance with in-depth analysis and monitoring",
        action: "View portfolio",
      },
      cardTwo: {
        title: "Don't let risk run wild, monitor everything",
        description:
          "Analyse your portfolio to make better strategy, risk, funding and investment decisions",
        action: "View risk solutions",
      },
      cardThree: {
        title: "Find investment opportunities",
        description:
          "Identify where the properties with the biggest upside and lowest price are",
        action: "View how",
      },
      cardFour: {
        title: "Need a tailored AVM?",
        description:
          "Blend our AVM with additional data to create new and advanced valuation models",
        action: "View taylor made AVMs",
      },
      cardFive: {
        title: "Turn your data into immediate impact",
        description:
          "A highly customizable framework according to your needs and the research you are conducting",
        action: "View observatory",
      },
    },
  },
  es: {
    t1: "Para el día a día",
    t2: "Soluciones innovadoras mejoradas por Inteligencia Artificial",
    t3: "Nuestros productos, su experiencia",
    sectionOne: {
      cardOne: {
        title: "Informes de valoración, sin complicaciones",
        description:
          "Cree y comparta informes con facilidad, impulsado por una base de datos histórica de más de 40 millones de propiedades",
        action: "Ver informes del mercado",
      },
      cardTwo: {
        title: "Aumente sus captaciones",
        description:
          "Ahorre tiempo y esfuerzo identificando nuevas oportunidades de negocio con nuestro módulo de metabúsqueda",
        action: "Ver metabúsqueda",
      },
      cardThree: {
        title: "Acceda a tendencias de mercado únicas",
        description:
          "Tome decisiones informadas accediendo a datos y análisis de mercado precisos y actualizados",
        action: "Ver insights",
      },
      cardFour: {
        title: "Cree un sitio web en 60 segundos",
        description:
          "Fortalezca su presencia en línea y lleve su negocio al siguiente nivel",
        action: "Ver sitio web",
      },
      cardFive: {
        title: "Maximice la conversión de leads",
        description:
          "Adelántese a su competencia identificando nuevas oportunidades antes que ellos",
        action: "Ver valuador digital",
      },
    },
    sectionTwo: {
      t1: "Soluciones de automatización",
      t2: "Valore y analice sus sus activos",
      t3: "Nuestros productos le permiten crear experiencias para sus clientes en torno a la propiedad inmobiliaria.",
      cardOne: {
        title: "Analice miles de activos por cartera",
        description:
          "Para inversores institucionales que deseen optimizar el rendimiento de su cartera con análisis y monitoreo en profundidad",
        action: "Ver cartera",
      },
      cardTwo: {
        title: "No permita que el riesgo se desate",
        description:
          "Monitoree su cartera para tomar mejores decisiones estratégicas de riesgo",
        action: "Ver soluciones de riesgo",
      },
      cardThree: {
        title: "Encuentre oportunidades",
        description:
          "Identifique dónde se encuentran las propiedades con mayores posibilidades y menor precio",
        action: "Ver cómo",
      },
      cardFour: {
        title: "¿Necesita un AVM a medida?",
        description:
          "Combine nuestro AVM con datos adicionales para crear un modelo de valoración nuevo y avanzado",
        action: "Ver AVMs a medida",
      },
      cardFive: {
        title: "Transforme sus datos en impacto inmediato",
        description:
          "Un marco altamente personalizable según sus necesidades e investigación que esté llevando a cabo",
        action: "Ver observatorio",
      },
    },
  },
};

export const INDEX = {
  pt: {
    t2: "A plataforma de Inteligência Artificial para os líderes do sector imobiliário",
    t3: "Avalie facilmente qualquer ativo, descubra oportunidades e feche o seu próximo negócio",
  },
  en: {
    t2: "The AI platform for top-tier real estate experts",
    t3: "Effortlessly estimate any asset, find opportunities and win over your next deal",
  },
  es: {
    t2: "La plataforma de Inteligencia Artificial para líderes en el sector inmobiliario",
    t3: "Valore activos sin complicaciones, descubra oportunidades y cierre su próximo negocio",
  },
};

export const VALUATIONS = {
  pt: {
    t2: "Estudos de Mercado",
    t3: "Um modelo de avaliação automática (AVM) de última geração potenciado pelos últimos desenvolvimentos em inteligência artificial e big data",
  },
  en: {
    t2: "Property Valuations",
    t3: "A state-of-the-art Automatic Valuation Model (AVM) using the latest developments in Artificial Intelligence and Big Data",
  },
  es: {
    t2: "Estudios de Mercado",
    t3: "Un Modelo de Valoración Automático utilizando los últimos desarrollos en inteligencia artificial y big data",
  },
};

export const METASEARCH = {
  pt: {
    t2: "Prospeção",
    t3: "Descubra oportunidades escondidas com a nossa ferramenta de pesquisa de imóveis, dados em tempo real e o histórico completo para qualquer imóvel",
  },
  en: {
    t2: "Metasearch",
    t3: "Discover hidden market gems with our comprehensive property search engine, giving you real-time data and a complete history of any property on the market",
  },
  es: {
    t2: "Buscador Inmobiliario",
    t3: "Descubra joyas del mercado ocultas con nuestro motor de búsqueda de propiedades completo, que le brinda datos en tiempo real y un historial completo de cualquier propiedad en el mercado",
  },
};

export const INSIGHTS = {
  pt: {
    t2: "Estatísticas de Mercado",
    t3: "Tenha acesso a estatísticas e tendências de mercado para qualquer região",
  },
  en: {
    t2: "Market Insights",
    t3: "Access unique real-time analytics and market trends for any region",
  },
  es: {
    t2: "Perspectivas del Mercado",
    t3: "Acceda a análisis únicos en tiempo real y tendencias del mercado para cualquier región",
  },
};

export const WEBSITE = {
  pt: {
    t2: "Website Pro",
    t3: "Crie um website profissional em 60 segundos, sem programar",
  },
  en: {
    t2: "Website Pro",
    t3: "Create a stunning website in 60 seconds, without any coding required",
  },
  es: {
    t2: "Sitio Web Profesional",
    t3: "Cree un sitio web impresionante en 60 segundos, sin necesidad de ningún código",
  },
};

export const DIGITAL_VALUER = {
  pt: {
    t2: "Avaliador Online",
    t3: "Integre as nossas soluções de geração e tratamento de leads para levar o seu negócio ao próximo nível",
  },
  en: {
    t2: "Digital Valuer",
    t3: "Integrate our automated lead handling tools to grow your business to the next level",
  },
  es: {
    t2: "Valorador Digital",
    t3: "Integre nuestras herramientas automatizadas de manejo de leads para llevar su negocio al siguiente nivel",
  },
};

export const ONE_SEARCH_BAR = {
  pt: {
    t1: "A melhor ferramenta de prospeção",
    t2: "Uma barra de pesquisa, todos os dados do mercado imobiliário",
    action: "Experimente a nossa aplicação",
  },
  en: {
    t1: "The best property browser",
    t2: "One Search Bar, All Real Estate Data",
    action: "Try our app",
  },
  es: {
    t1: "El mejor buscador inmobiliario",
    t2: "Una barra de búsqueda, todos los datos inmobiliarios",
    action: "Pruebe nuestra aplicación",
  },
};

export const PORTFOLIO = {
  pt: {
    t2: "Gestão de Portfólio",
    t3: "Otimize a yield e mitigue o risco monitorizando cada imóvel individualmente",
  },
  en: {
    t2: "Portfolio Management",
    t3: "Optimize yield and mitigate risk by monitoring each property individually",
  },
  es: {
    t2: "Gestión de Cartera",
    t3: "Optimice el rendimiento y elimine el riesgo monitoreando cada propiedad individualmente",
  },
};

export const RISK = {
  pt: {
    t2: "Análise de Risco",
    t3: "Análises acionáveis para os seus imóveis e hipotecas para identificar novas oportunidades no seu portfólio",
  },
  en: {
    t2: "Risk Analysis",
    t3: "Actionable analysis of your existing properties and mortgages to identify new opportunities within your portfolio",
  },
  es: {
    t2: "Análisis de Riesgo",
    t3: "Análisis accionable de sus propiedades e hipotecas existentes para identificar nuevas oportunidades dentro de su cartera",
  },
};

export const OPPORTUNITY = {
  pt: {
    t2: "Encontre Oportunidades",
    t3: "Seja o primeiro a encontrar as melhores oportunidades de investimento",
  },
  en: {
    t2: "Find Opportunities",
    t3: "Find the best investment deals before the market",
  },
  es: {
    t2: "Encuentra Oportunidades",
    t3: "Adelántese al mercado y encuentre las mejores inversiones",
  },
};

export const AVMS = {
  pt: {
    t2: "Soluções de AVM",
    t3: "Combine os seus dados com a nossa tecnologia de avaliação automática (AVM)",
  },
  en: {
    t2: "Valuation Solutions",
    t3: "Blend our AVM with additional data o create new and advanced valuation models",
  },
  es: {
    t2: "Soluciones de Valoración",
    t3: "Combine nuestro AVM con datos adicionales para crear nuevos y avanzados modelos de valoración",
  },
};

export const OBSERVATORY = {
  pt: {
    t2: "Observatório",
    t3: "Ganhe uma perspetiva única sobre o comportamento do mercado para qualquer região e segmento",
  },
  en: {
    t2: "Observatory",
    t3: "Gain a unique perspective into how the market is behaving for any region of interest and for any property segment",
  },
  es: {
    t2: "Observatorio de Datos",
    t3: "Obtenga una perspectiva única sobre cómo se comporta el mercado para cualquier región de interés y para cualquier segmento de propiedad",
  },
};

export const FEATURES_VALUATIONS = {
  pt: {
    title: "Avaliações mais rápidas e eficientes",
    subTitle:
      "Acelere e simplifique a criação de estudos de mercado com a nossa tecnologia de ponta. Crie estudos de mercado personalizados e detalhados em segundos, potenciando o valor entregue aos seus clientes",
    valuation: {
      title: "Simplifique os seus processos de reporting",
      description:
        "Apresente a sua análise ao cliente com confiança, através de uma estimativa precisa dos valores de venda e arrendamento do imóvel",
    },
    comparables: {
      title: "Encontre e selecione comparáveis",
      description:
        "Aumente a sua credibilidade junto do cliente com comparáveis ajustados a qualquer tipo de propriedade e condições de mercado",
    },
    share: {
      title: "Relatórios apelativos para impressionar",
      description:
        "Destaque-se da concorrência através da criação e partilha de relatórios apelativos e informativos que realçam os pontos chave do imóvel",
    },
    customize: {
      title: "Personalize tudo",
      description:
        "Ganhe a atenção do cliente com profissionalismo e atenção ao detalhe, realçando o seu conhecimento sobre o mercado",
    },
    organize: {
      title: "Mantenha-se organizado",
      description:
        "Organize os seus relatórios em Ativos, Referidos, Vendidos e Perdidos",
    },
    portfolio: {
      title: "Aproveite o seu histórico",
      description:
        "A nossa feature exclusiva permite-lhe fazer upload das suas vendas e ofertas e exibi-las no seu site pessoal, ajudando a criar a sua história",
    },
    trends: {
      title: "Descubra tendências únicas na região",
      description:
        "Aceda a informação única sobre a vizinhaça do imóvel, incluindo pontos de interesse, tendências e estatísticas chave",
    },
    brand: {
      title: "Fortaleça a sua marca",
      description:
        "Ajuste e personalize todos os aspectos do relatório que alavancam o seu conhecimento e a sua marca",
    },
  },
  en: {
    title: "Faster, more efficient valuations",
    subTitle:
      "Accelerate and streamline your property valuations with our cutting-edge technology. Easily customize and generate detailed reports in seconds, allowing you to deliver unparalleled value to your customers",
    valuation: {
      title: "Streamline your reporting process",
      description:
        "Determine accurate prices and yields for any property, and confidently negotiate deals",
    },
    comparables: {
      title: "Identify and select comparable properties",
      description:
        "Unlock the full potential of your pricing strategy with accurate comparable data and advanced filtering options for any type of property",
    },
    share: {
      title: "Impress customers with stunning reports",
      description:
        "Stand out from the competition by creating and sharing visually stunning, informative reports that highlight key property data and insights",
    },
    customize: {
      title: "Customize everything",
      description:
        "Enhance your customer engagement with professionally crafted and fully customizable reports, showcasing your expertise and brand",
    },
    organize: {
      title: "Keep organized",
      description: "Organize your reports as Active, Referred, Sold and Lost",
    },
    portfolio: {
      title: "Leverage your track record",
      description:
        "Our unique feature lets you upload your own sales and offers and show them on your personal website, helping you build your story",
    },
    trends: {
      title: "Unlock insights into local market trends",
      description:
        "Gain a comprehensive understanding of the property's local market with detailed information on trends, points of interest and key statistics",
    },
    brand: {
      title: "Power your brand",
      description:
        "Adjust and customize every report aspect leveraging your knowledge and brand",
    },
  },
  es: {
    title: "Valoraciones más rápidas y eficientes",
    subTitle:
      "Acelere y agilice sus valoraciones de propiedades con nuestra tecnología de vanguardia",
    valuation: {
      title: "Agilice su proceso de informes",
      description:
        "Determine los precios y rendimientos precisos para cualquier propiedad, teniendo el poder de negociar los acuerdos con confianza",
    },
    comparables: {
      title: "Identifique y seleccione propiedades comparables",
      description:
        "Desbloquee el máximo potencial de su estrategia de precios con datos comparables precisos y opciones avanzadas de filtrado para cualquier tipo de propiedad",
    },
    share: {
      title: "Impresione a los clientes con informes impresionantes",
      description:
        "Destaque frente a la competencia creando y compartiendo informes visualmente impresionantes e informativos que resalten los datos y las perspectivas clave de la propiedad",
    },
    customize: {
      title: "Personalice todo",
      description:
        "Mejore su compromiso con los clientes con informes profesionalmente elaborados y totalmente personalizables, que destaquen su experiencia y su marca",
    },
    organize: {
      title: "Manténgase organizado",
      description:
        "Organice sus informes como activos, referidos, vendidos y perdidos",
    },
    portfolio: {
      title: "Aproveche su historial",
      description:
        "Nuestra función única le permite cargar sus propias ventas y ofertas y mostrarlas en su sitio web personal, ayudándole a construir su historia",
    },
    trends: {
      title:
        "Desbloquee las perspectivas sobre las tendencias del mercado local",
      description:
        "Obtenga una comprensión completa del mercado local de la propiedad con información detallada sobre tendencias, puntos de interés y estadísticas clave",
    },
    brand: {
      title: "Potencie su marca",
      description:
        "Ajuste y personalice cada aspecto del informe aprovechando su conocimiento y su marca",
    },
  },
};

export const FEATURES_METASEARCH = {
  pt: {
    title: "Uma barra de pesquisa, toda a informação",
    subTitle:
      "Descubra as melhores oportunidades em qualquer região para os seus critérios de pesquisa",
    sources: {
      title: "Uma plataforma, múltiplas fontes",
      description:
        "Conheça todo o histórico de um imóvel através da junção de múltiplas fontes de informação",
    },
    fsbo: {
      title: "Encontre oportunidades for-sale-by-owner (FSBO)",
      description:
        "Saiba onde se encontram potenciais angariações assim que entram no mercado",
    },
    history: {
      title: "Conheça o histórico",
      description:
        "Conheça o histórico de preços e angariações de cada propriedade",
    },
    alerts: {
      title: "Crie alertas e receba notificações",
      description:
        "Receba um email sempre que um novo imóvel entra no mercado de acordo com os seus critérios",
    },
    statistics: {
      title: "Descubra as estatísticas",
      description:
        "Construa relatórios de tendência de forma instantânea para qualquer área desenhada através do nosso módulo Insights",
    },
    valuations: {
      title: "Identifique imóveis subvalorizados",
      description:
        "Compare o preço de mercado de um imóvel com o valor de venda dado pelo nosso algoritmo com apenas um click",
    },
    map: {
      title: "Motor de busca rápido com pesquisa por mapa",
      description:
        "Use o mapa interativo ou pesquise por endereço ou mercado, para identificar e selecionar a lista de imóveis que correspondem aos seus critérios",
    },
    favorites: {
      title: "Guarde favoritos e coleções",
      description:
        "Organize as suas pesquisas em listas curadas de imóveis fáceis de partilhar com os seus clientes",
    },
  },
  en: {
    title: "One search bar, all property information",
    subTitle:
      "Uncover the best property deals in any region according to your search criteria",
    sources: {
      title: "One platform, multiple information sources",
      description:
        "Know a property's full record by fusing multiple sources of information into a single listing",
    },
    fsbo: {
      title: "Find for-sale-by-owner opportunities",
      description:
        "Grow your business by discovering new opportunities as soon as they hit the market",
    },
    history: {
      title: "Know the history",
      description:
        "Get to know the price and referrals history of each property",
    },
    alerts: {
      title: "Stay ahead of the game",
      description:
        "Receive daily alerts whenever new properties matching your search criteria enter the market",
    },
    statistics: {
      title: "Unveil the statistics",
      description:
        "Build trend reports instantly for any drawn area through our insights module",
    },
    valuations: {
      title: "Identify and understand mispriced properties",
      description:
        "Compare a property's listing price against our Automated Valuation Model by creating a property valuation report in one click",
    },
    map: {
      title: "Fast engine with map search",
      description:
        "Use the interactive map view or search by address or market, to identify and narrow down the list of properties that meet your criteria.",
    },
    favorites: {
      title: "Set favorites and create collections of properties",
      description:
        "Organize your searches into curated lists to easily monitor potential market opportunities and share them with your customers",
    },
  },
  es: {
    title: "Una barra de búsqueda, toda la información del inmobiliario",
    subTitle:
      "Descubra las mejores ofertas de propiedades en cualquier región según sus criterios de búsqueda",
    sources: {
      title: "Una plataforma, múltiples fuentes de información",
      description:
        "Conozca el registro completo de una propiedad fusionando múltiples fuentes de información en una sola lista",
    },
    fsbo: {
      title: "Encuentre oportunidades de venta por el propietario",
      description:
        "Haga crecer su negocio descubriendo nuevas oportunidades tan pronto como lleguen al mercado",
    },
    history: {
      title: "Conozca el historial",
      description:
        "Conozca el historial de precios y referencias de cada propiedad",
    },
    alerts: {
      title: "Manténgase al día en el juego",
      description:
        "Reciba alertas diarias cada vez que nuevas propiedades que cumplan con sus criterios de búsqueda entren al mercado",
    },
    statistics: {
      title: "Descubra las estadísticas",
      description:
        "Construya informes de tendencias al instante para cualquier área dibujada a través de nuestro módulo de perspectivas",
    },
    valuations: {
      title: "Identifique y comprenda las propiedades con precios incorrectos",
      description:
        "Compare el precio de listado de una propiedad con nuestro Modelo de Valoración Automatizado creando un informe de valoración de propiedad en un solo clic",
    },
    map: {
      title: "Motor rápido con búsqueda en mapa",
      description:
        "Use la vista de mapa interactivo o busque por dirección o mercado, para identificar y acotar la lista de propiedades que cumplan con sus criterios.",
    },
    favorites: {
      title: "Establezca favoritos y cree colecciones de propiedades",
      description:
        "Organice sus búsquedas en listas seleccionadas para supervisar fácilmente las oportunidades del mercado potenciales y compartirlas con sus clientes",
    },
  },
};

export const FEATURES_INSIGHTS = {
  pt: {
    title: "Aceda a dados em tempo real",
    subTitle:
      "Ganhe uma perspetiva única sobre o mercado em qualquer região, para qualquer segmento",
    indicators: {
      title: "Tomada de decisão baseada em dados",
      description:
        "Um conjunto completo de indicadores e estatísticas sobre as condições para auxiliar a tomada de decisão informada",
    },
    distributions: {
      title: "Análises detalhadas",
      description:
        "A flexibilidade que precisa para ter uma análise 100% ajustada de acordo com os seus critérios de pesquisa para as atuais condições de mercado",
    },
    map: {
      title: "Desenhar no mapa ou refinar",
      description:
        "Da pesquisa de polígonos a qualquer tipo de filtros, a área de resultados é apresentada em segundos",
    },
    report: {
      title: "Impressione os seus clientes",
      description:
        "Destaque-se da concorrência, apresentando aos seus clientes relatórios apelativos e que apresentam métricas chave",
    },
    notifications: {
      title: "Mantenha-se a par",
      description:
        "Seja notificado mensalmente sobre as principais alterações no mercado no último mês para os seus critérios de pesquisa",
    },
  },
  en: {
    title: "Access real-time data",
    subTitle:
      "Gain a unique perspective into how the market is behaving for any region and any property segment",
    indicators: {
      title: "Valuable data to help you make informed decisions",
      description:
        "A comprehensive list of indicators and trends on market conditions to support your analysis and decision processes",
    },
    distributions: {
      title: "Go deep in your analysis",
      description:
        "The flexibility you need to have a thorough reporting according to your search criteria and under current market conditions",
    },
    map: {
      title: "Draw on map or refine",
      description:
        "From polygon search to any type of filtering, results area presented in seconds",
    },
    report: {
      title: "Impress customers with stunning reports",
      description:
        "Stand out from the competition by creating and sharing visually stunning, informative reports that highlight key insights and trends",
    },
    notifications: {
      title: "Stay ahead of the curve",
      description:
        "Get notified monthly with a detailed report analysing the main indicators and trends of the past month according to your search criteria",
    },
  },
  es: {
    title: "Acceda a datos en tiempo real",
    subTitle:
      "Obtenga una perspectiva única sobre cómo se comporta el mercado para cualquier región y cualquier segmento de propiedad",
    indicators: {
      title: "Datos valiosos para ayudarle a tomar decisiones informadas",
      description:
        "Una lista completa de indicadores y tendencias sobre las condiciones del mercado para apoyar sus procesos de análisis y toma de decisiones",
    },
    distributions: {
      title: "Profundice en su análisis",
      description:
        "La flexibilidad que necesita para tener un informe exhaustivo según sus criterios de búsqueda y bajo las condiciones actuales del mercado",
    },
    map: {
      title: "Dibuje en el mapa o afine",
      description:
        "Desde la búsqueda de polígonos hasta cualquier tipo de filtrado, los resultados se presentan en segundos",
    },
    report: {
      title: "Impresione a los clientes con informes impresionantes",
      description:
        "Destaque frente a la competencia creando y compartiendo informes visualmente impresionantes e informativos que resalten las perspectivas y tendencias clave",
    },
    notifications: {
      title: "Manténgase a la vanguardia",
      description:
        "Reciba notificaciones mensuales con un informe detallado analizando los principales indicadores y tendencias del mes anterior según sus criterios de búsqueda",
    },
  },
};

export const FEATURES_WEBSITE = {
  pt: {
    view: "Ver website",
    header: "",
    title: "Crie o seu site em poucos minutos",
    subTitle: "Fortaleça a sua presença online sem necessidade de programar",
    website: {
      title: "Todos os profissionais precisam de uma presença digital",
      description:
        "Ter uma presença online forte é essencial para adquirir novos clientes e fortalecer a reputação",
    },
    properties: {
      title: "Exiba as suas vendas e angariações",
      description:
        "Apresente o seu histórico e portfólio para se credibilizar e encontrar novos clientes",
    },
    blog: {
      title: "Mantenha o seu público e o seu blog atualizados",
    },
    testimonials: {
      title: "Construa confiança com testemunhos",
      description:
        "Orgulhosamente apresente as opiniões dos seus clientes para elevar a sua credibilidade",
    },
    edit: {
      title: "Edição em tempo real",
    },
    seo: {
      title: "Otimizado para SEO",
      description:
        "Google Tag Manager, Google Analytics, Facebook Pixel, a escolha é sua",
    },
    brand: {
      title: "Fortaleça a sua marca",
      description:
        "Total customização do website para refletir a sua marca e tom de comunicação",
    },
    upgradeSection: {
      header: "Faça upgrade para o Website Pro",
      title: "Torne-se Profissional",
    },
    upgrade: {
      title: "Faça upgrade para Profissional por WEBSITE_PRICE",
      description:
        "Para profissionais do setor imobiliário que desejam ir mais além - adicione o seu logotipo, domínio próprio e um sistema de geração de leads com validação por SMS",
    },
  },
  en: {
    view: "View website",
    header: "",
    title: "Create your website in no time",
    subTitle: "Strengthen your online presence with no coding required",
    website: {
      title: "All professionals need an online presence",
      description:
        "Having a strong online presence is essential to reach new customers and grow your reputation",
    },
    properties: {
      title: "Leverage your track record and portfolio",
      description:
        "Showcase your sales and offers to build your brand and connect with customers",
    },
    blog: {
      title: "Keep your audience and blog updated",
    },
    testimonials: {
      title: "Build trust with client testimonials",
      description:
        "Prominently display client testimonials to elevate your credibility",
    },
    edit: {
      title: "Real time editing",
    },
    seo: {
      title: "SEO compliant",
      description:
        "Google tag manager, google analytics, facebook pixel, you name it",
    },
    brand: {
      title: "Supercharge your brand",
      description:
        "Fully customizable website that reflects your unique style and message.",
    },
    upgradeSection: {
      header: "Upgrade to Website Pro",
      title: "Go Professional",
    },
    upgrade: {
      title: "Upgrade to Professional for WEBSITE_PRICE",
      description:
        "For real estate professionals who want to step up their game - add your logo, custom domain, and a lead generation system with SMS validation",
    },
  },
  es: {
    view: "Visita el sitio web",
    header: "",
    title: "Cree su sitio web en un instante",
    subTitle: "Fortalezca su presencia en línea sin requerir código",
    website: {
      title: "Todos los profesionales necesitan una presencia en línea",
      description:
        "Tener una presencia en línea sólida es esencial para llegar a nuevos clientes y crecer su reputación",
    },
    properties: {
      title: "Aproveche su historial y cartera",
      description:
        "Muestre sus ventas y ofertas para construir su marca y conectarse con los clientes",
    },
    blog: {
      title: "Mantenga su audiencia y blog actualizado",
    },
    testimonials: {
      title: "Construya confianza con testimonios de clientes",
      description:
        "Muestre los testimonios de los clientes para elevar su credibilidad",
    },
    edit: {
      title: "Edición en tiempo real",
    },
    seo: {
      title: "Amigable con el SEO",
      description:
        "Google tag manager, google analytics, facebook pixel, lo que necesite",
    },
    brand: {
      title: "Potencie su marca",
      description:
        "Sitio web completamente personalizable que refleja su estilo y mensaje únicos",
    },
    upgradeSection: {
      header: "Actualice a Website Pro",
      title: "¿Busca lo profesional?",
    },
    upgrade: {
      title: "Actualice a Profesional por WEBSITE_PRICE",
      description:
        "Para profesionales inmobiliarios que quieren dar el siguiente paso - agregue su logotipo, dominio personalizado y un sistema de generación de leads con validación SMS",
    },
  },
};

export const FEATURES_DIGITAL_VALUER = {
  pt: {
    visit: "Visitar website",
    title: "Obtenha leads de negócio através de estimativas de valor gratuitas",
    subTitle:
      "Gere mais tráfego e leads, permita que potenciais clientes estimem o valor dos seus imóveis",
    leads: {
      title: "Facilidade de conversão de leads e follow-up",
      description:
        "Aumente a taxa de conversão e reduza o tempo de follow-up através da criação automática de um estudo de mercado para cada lead",
    },
    brand: {
      title: "O seu negócio, as suas regras",
      description:
        "Ferramenta totalmente customizável de acordo com a identidade da sua marca e tom de comunicação",
    },
    tracking: {
      title: "Distribua e acompanhe todas as leads",
      description:
        "Acompanhe todas as leads e decida de que forma são distribuídas pelos agentes de maneira a maximizar a conversão",
    },
    agent: {
      title: "Multiplique os seus esforços na geração de leads",
      description:
        "Cada consultor da sua agência pode ter a sua própria página customizada, fornecendo-lhe mais um canal onde cada um pode promover individualmente a página de leads",
    },
    faqSection: {
      title: "FAQ",
      subTitle: "",
    },
  },
  en: {
    visit: "Visit website",
    title: "Unlock new growth opportunities with free property valuations",
    subTitle:
      "Attract more traffic and potential leads by creating an easy way for prospective sellers value their property",
    leads: {
      title: "Efficient lead conversion and follow-up",
      description:
        "Improve your lead conversion ratio and reduce follow-up time by receiving a property valuation report on each new visitor",
    },
    brand: {
      title: "Your website, your rules",
      description:
        "Fully customizable digital valuer, tailored to match your brand's identity and give you complete control over all aspects",
    },
    tracking: {
      title: "Streamline lead distribution and tracking",
      description:
        "Easily manage and distribute leads to the right agents for maximum conversion and efficiency",
    },
    agent: {
      title: "Multiply your lead generation efforts",
      description:
        "The digital valuer allows each agent to have their own custom page providing agents with a unique and personalized way to generate leads, increasing the total number of leads generated",
    },
    faqSection: {
      title: "FAQ",
      subTitle: "",
    },
  },
  es: {
    visit: "Visita el sitio web",
    title:
      "Desbloquee nuevas oportunidades de crecimiento con valoraciones de propiedades gratuitas",
    subTitle:
      "Atraiga más tráfico y posibles leads creando una forma fácil para que los vendedores potenciales valoren su propiedad",
    leads: {
      title: "Conversión eficiente de leads y seguimiento",
      description:
        "Mejora su conversión de leads y reduzca el tiempo de seguimiento, recibiendo un informe de valuación de propiedades con cada nuevo posible cliente",
    },
    brand: {
      title: "Su sitio web, sus reglas",
      description:
        "Valuador digital completamente personalizable, adaptado para que coincida con la identidad de su marca y le brinde un control completo sobre todos los aspectos",
    },
    tracking: {
      title: "Optimice la distribución y seguimiento de leads",
      description:
        "Administre y distribuya fácilmente los leads a los agentes adecuados para una máxima eficiencia y conversión",
    },
    agent: {
      title: "Multiplique su capacidad de generación de leads",
      description:
        "El valuador digital permite que cada agente tenga su propia página personalizada, proporcionando a los agentes una forma única y personalizada de generar leads, aumentando el número total de leads generados",
    },
    faqSection: {
      title: "Preguntas frecuentes",
      subTitle: "",
    },
  },
};

export const FEATURES_PORTFOLIO = {
  pt: {
    title: "Analise milhares de imóveis por portfólio",
    subTitle:
      "Para investidores institucionais e profissionais do setor que queiram otimizar o desempenho do portfólio através de análises detalhadas e monitorização em tempo real",
    valuation: {
      title: "Avaliação precisa",
      description:
        "Alimentado pelo modelo de avaliação automática (AVM) de última geração, usando os mais recentes desenvolvimentos em inteligência artificial e big data",
    },
    monitoring: {
      title: "Monitorização contínua",
      description:
        "Analise a performance do seu portfólio com métricas e indicadores detalhados para poder tomar decisões de investimento informadas",
    },
    report: {
      title: "Um imóvel, um relatório",
      description:
        "Tenha acesso a um estudo de mercado detalhado por cada imóvel, para ser facilmente partilhado com o resto da sua equipa",
    },
    comparables: {
      title: "Comparáveis e yields",
      description:
        "Tenha acesso a uma lista de comparáveis e estimativa de yield para cada imóvel para poder ir ao máximo detalhe na sua análise",
    },
    overview: {
      title: "Análise detalhada",
      description:
        "Entenda detalhadamente o comportamento dos mercados onde se encontra o seu portfólio com os indicadores que impactam a performance",
    },
  },
  en: {
    title: "Analyse thousands of assets per portfolio",
    subTitle:
      "For institutional investors and real estate professionals who want to optimize portfolio performance through detailed analysis and real-time monitoring",
    valuation: {
      title: "Accurate valuations",
      description:
        "Powered by state-of-the-art Automatic Valuation Model (AVM) using the latest developments in Artificial Intelligence and Big Data",
    },
    monitoring: {
      title: "Real-time monitoring",
      description:
        "Track and analyse your portfolio's performance with detailed insights and metrics, allowing you to make informed investment decisions",
    },
    report: {
      title: "One property, one report",
      description:
        "Have access to a detailed valuation report for each property to be easily shared with others",
    },
    comparables: {
      title: "Comparables and yields",
      description:
        "Get a list of comparables for each property in your portfolio as well as a yield estimation so you can dive even deeper into the numbers",
    },
    overview: {
      title: "Detailed analysis",
      description:
        "Gain a deeper understanding of the markets in which your portfolio is located, including the key variables that will impact performance",
    },
  },
  es: {
    title: "Analice miles de activos por cartera",
    subTitle:
      "Para inversores institucionales y profesionales inmobiliarios que quieren optimizar el rendimiento de la cartera a través de análisis detallados y monitoreo en tiempo real",
    valuation: {
      title: "Valoraciones precisas",
      description:
        "Impulsado por el modelo de valoración automático (AVM) de última generación utilizando las últimas novedades en inteligencia artificial y gran cantidad de datos",
    },
    monitoring: {
      title: "Monitoreo en tiempo real",
      description:
        "Rastree y analice el rendimiento de su cartera con detalles e información, lo que le permitirá tomar decisiones de inversión informadas",
    },
    report: {
      title: "Una propiedad, un informe",
      description:
        "Tenga acceso a un informe detallado de valoración para cada propiedad, teniendo el poder de compartirlo fácilmente con otros",
    },
    comparables: {
      title: "Comparables e ingresos",
      description:
        "Obtenga una lista de comparables para cada propiedad en su cartera, así como una estimación de ingresos para que pueda sumergirse aún más en los números",
    },
    overview: {
      title: "Análisis detallado",
      description:
        "Obtenga una comprensión más profunda de los mercados en los que se encuentra su cartera, incluyendo las variables clave que impactarán en el rendimiento",
    },
  },
};

export const FEATURES_RISK = {
  pt: {
    title: "Mitigar o risco através da automação",
    subTitle:
      "Automatize a sua análise de portfólio para poder tomar melhores decisões de investimento e risco",
    confidence: {
      title: "Proceda com confiança",
      description:
        "Identifique potenciais riscos com um intervalo de confiança para cada imóvel",
    },
    report: {
      title: "Análises avançadas",
      description:
        "Análises detalhadas ao nível do imóvel e portólio, incluindo estimativas de valor para cada imóvel com intervalo de confiança",
    },
    benchmark: {
      title: "Métricas de performance",
      description:
        "Compare a performance do seu portfólio com indicadores de mercado, tais como taxas de juro, liquidez e retorno de mercado",
    },
    monitoring: {
      title: "Monitorização",
      description:
        "Reavaliação constante de todos os ativos para que os modelos existentes possam usar as estimativas de preços mais recentes",
    },
    overview: {
      title: "Específico ao negócio",
      description:
        "Variáveis e resultados personalizados de acordo com as características do seu portfólio e negócio",
    },
  },
  en: {
    title: "Mitigate risk through automation",
    subTitle:
      "Automate your property portfolio analysis to make better strategy, risk, funding and investment decisions",
    confidence: {
      title: "Procceed with confidence",
      description:
        "Easily identify potential risks with confidence level data for every property valuation",
    },
    report: {
      title: "Advanced analysis",
      description:
        "Detailed property and portfolio-level analysis, including individual valuations (capital and rental) and confidence levels",
    },
    benchmark: {
      title: "Performance metrics",
      description:
        "Benchmark your portfolio performance against market indicators such as house price inflation, rental growth, interest rates and market liquidity",
    },
    monitoring: {
      title: "Real-time monitoring",
      description:
        "Continuous valuation of all properties so your existing risk models can use the most up-to-date information",
    },
    overview: {
      title: "Business-specific",
      description:
        "Whether your portfolio is a prime mortgage book, non-performing loans, buy-to-let or rented, we'll design the outputs to meet your business needs",
    },
  },
  es: {
    title: "Elimine el riesgo a través de la automatización",
    subTitle:
      "Automatice su análisis de cartera de propiedades para tomar mejores decisiones de estrategia, riesgo, financiamiento e inversión",
    confidence: {
      title: "Avanzar con confianza",
      description:
        "Identifique fácilmente los riesgos potenciales con datos de nivel de confianza para cada valoración de propiedad",
    },
    report: {
      title: "Análisis avanzado",
      description:
        "Análisis detallado a nivel de propiedad y cartera, incluyendo valoraciones individuales (capital y alquiler) y niveles de confianza",
    },
    benchmark: {
      title: "Métricas de rendimiento",
      description:
        "Compare el rendimiento de su cartera con indicadores del mercado como la inflación de precios de la vivienda, el crecimiento del alquiler, las tasas de interés y la liquidez del mercado",
    },
    monitoring: {
      title: "Monitoreo en tiempo real",
      description:
        "Valoración continua de todas las propiedades para que sus modelos de riesgo existentes puedan utilizar la información más actualizada",
    },
    overview: {
      title: "Específico para negocios",
      description:
        "Ya sea un libro de hipotecas prime, préstamos no performantes, compra-alquiler o alquiler, diseñaremos las salidas para satisfacer sus necesidades comerciales",
    },
  },
};

export const FEATURES_OPPORTUNITIES = {
  pt: {
    title: "Agarre automaticamente as melhores oportunidades de investimento",
    subTitle:
      "Seja o primeiro a agir sobre potenciais oportunidades de investimento através da monitorização contínua do mercado",
    analysis: {
      title:
        "Análise de mercado recorrente, automática e detalhada dos imóveis",
    },
    emails: {
      title: "Descubra novas oportunidades",
      description:
        "Identifique imóveis subvalorizados assim que entram no mercado para ver se se enquadram na sua estratégia",
    },
    advisor: {
      title: "Seja o conselheiro dos seus clientes",
      description:
        "Dê aos seus clientes dados acionáveis que lhes permita decidir se é a altura certa de comprar ou vender um determinado imóvel",
    },
  },
  en: {
    title:
      "Automatically source and underwrite the best investment opportunities",
    subTitle:
      "Be the first to act on the potential investment opportunities with our automatic market-wide coverage tools",
    analysis: {
      title:
        "Recurring, automated and detailed market analysis for each property",
      description: "",
    },
    emails: {
      title: "Uncover investment opportunities",
      description:
        "Identify undervalued properties as soon as they enter the market to see if they meet your investment criteria",
    },
    advisor: {
      title: "Be your client's sound advisor",
      description:
        "Give them actionable data points to decide if it's the right time to sell or buy a given property and be their trusted advisor",
    },
  },
  es: {
    title:
      "Surtir y avaluar automáticamente las mejores oportunidades de inversión",
    subTitle:
      "Sea el primero en actuar sobre las posibles oportunidades de inversión con nuestras herramientas de cubrimiento automático del mercado",
    analysis: {
      title:
        "Análisis del mercado recurrente, automatizado y detallado para cada propiedad",
      description: "",
    },
    emails: {
      title: "Descubrir oportunidades de inversión",
      description:
        "Identificar propiedades subvaloradas en cuanto entran al mercado para ver si cumplen con sus criterios de inversión",
    },
    advisor: {
      title: "Ser el asesor sólido de su cliente",
      description:
        "Darles puntos de datos concretos para decidir si es el momento adecuado para vender o comprar una determinada propiedad y ser su asesor de confianza",
    },
  },
};

export const FEATURES_AVMS = {
  pt: {
    header: "Tire proveito dos seus dados",
    title: "Construa o seu modelo de avaliação",
    subTitle:
      "Combine o potencial das suas bases de dados com a nossa experiência e capacidade tecnológica",
    trusted: {
      title: "Customizado ao seu negócio",
      description:
        "Empresas de referência do ramo confiam no nosso AVM para tomar decisões essenciais no seu dia-a-dia",
    },
    fast: {
      title: "Preciso e flexível",
      description:
        "Utilize os mais recentes desenvolvimentos em Inteligência Artificial para fazer estimativas de valor para qualquer imóvel",
    },
    integration: {
      title: "Fácil de integrar",
      description:
        "Simplifique o processo de integração com as nossas APIs modulares, abstraindo-se da complexidade tecnológica",
    },
    rules: {
      title: "Os seus dados, as suas regras",
      description:
        "Potenciado por algoritmos avançados, permite-lhe ter acesso a estimativas precisas adaptadas à sua realidade",
    },
  },
  en: {
    header: "Take advantage of your data",
    title: "Build your own AVM",
    subTitle:
      "Leverage the power of your datasets fusing them with our technological capabilities and expertise",
    trusted: {
      title: "Tailormade to your business",
      description:
        "Top real estate companies trust our AVM expertise making it a trusted essential in their day-to-day activities",
    },
    fast: {
      title: "Fast and accurate",
      description:
        "Leverage the latest breakthroughs the artificial intelligence and big data to make accurate property valuations",
    },
    integration: {
      title: "Easy to integrate",
      description:
        "Simplify integration with our modular and flexible API, abstracting technological complexity for quick and seamless integration",
    },
    rules: {
      title: "Your data, your rules",
      description:
        "Powered by advanced algorithms and large datasets, which allows for the most up-to-date and accurate property valuations",
    },
  },
  es: {
    header: "Aprovecha tus datos",
    title: "Construye tu propio AVM",
    subTitle:
      "Aprovecha el poder de tus conjuntos de datos fusionándolos con nuestras capacidades tecnológicas y experiencia",
    trusted: {
      title: "A medida para tu negocio",
      description:
        "Las mejores empresas inmobiliarias confían en nuestra experiencia en AVM, convirtiéndolo en una herramienta esencial y confiable en sus actividades diarias",
    },
    fast: {
      title: "Rápido y preciso",
      description:
        "Aprovecha los últimos avances en inteligencia artificial y big data para hacer valoraciones precisas de propiedades",
    },
    integration: {
      title: "Fácil de integrar",
      description:
        "Simplifica la integración con nuestra API modular y flexible, abstrae la complejidad tecnológica para una integración rápida y sin problemas",
    },
    rules: {
      title: "Tus datos, tus reglas",
      description:
        "Alimentado por algoritmos avanzados y grandes conjuntos de datos, lo que permite las valoraciones de propiedades más actualizadas y precisas",
    },
  },
};

export const FEATURES_OBSERVATORY = {
  pt: {
    title: "Deixe os dados contarem a sua história",
    subTitle:
      "Construa um produto de visualização de dados apelativo para uma experiência de utilizador melhorada",
    technology: {
      title: "A sua narrativa e experiência, a nossa tecnologia",
      description:
        "Use o poder dos dados para contar a sua história, utilizando a nossa capacidade tecnológica e experiência",
    },
    development: {
      title: "Acelere o seu processo de desenvolvimento",
      description:
        "Descubra tendências e padrões escondidos com as nossas ferramentas de visualização espacial e de gestão de dados",
    },
    trusted: {
      title: "Utilizado por instituições governamentais",
      description:
        "O nosso Observatório de Dados torna possível as instituições darem acesso seguro dos seus dados às suas audiências",
    },
  },
  en: {
    title: "Tell your story by interacting with data",
    subTitle:
      "Design a visually engaging data visualization product for better storytelling and user experience",
    technology: {
      title: "Your narrative, your experience, our technology",
      description:
        "Design a powerful data story using our expert engineering team and advanced spatial analysis capabilities",
    },
    development: {
      title: "Speed up your development process",
      description:
        "Uncover new insights and hidden patterns that were previously hidden with our streamlined data management and analysis tools",
    },
    trusted: {
      title: "Trusted by government institutions",
      description:
        "Our data observatory makes it simple for organizations to securely give access to their data to their target audiences",
    },
  },
  es: {
    title: "Cuente su historia interactuando con datos",
    subTitle:
      "Diseñe un producto de visualización de datos atractivo visualmente para una mejor narración de historias y experiencia de usuario",
    technology: {
      title: "Su narrativa, su experiencia, nuestra tecnología",
      description:
        "Diseñe una historia de datos poderosa utilizando nuestro equipo de ingenieros expertos y capacidades avanzadas de análisis espacial",
    },
    development: {
      title: "Acelera tu proceso de desarrollo",
      description:
        "Descubra nuevas perspectivas y patrones que anteriormente estaban ocultos, gracias a nuestras herramientas de gestión y análisis de datos optimizadas",
    },
    trusted: {
      title: "La confianza de instituciones gubernamentales",
      description:
        "Nuestro observatorio de datos hace que sea simple para las organizaciones brindar acceso seguro a sus datos por parte de su audiencia objetiva",
    },
  },
};

export const FAQ_DIGITAL_VALUER = {
  pt: [
    {
      title: "O que é o Avaliador Online?",
      lines: [
        `O Avaliador Online é uma ferramenta que pode ser integrada no seu site para
        gerar novas leads. Também automatiza muitos processos no tratamento de leads, nomeadamente
        a criação de estudos de mercado que os consultores necessitam antes de entrar
        em contacto com potenciais clientes. É totalmente personalizável de acordo com a
        marca da sua agência, dando-lhe controlo total sobre o design e tom da ferramenta.`,
      ],
    },
    {
      title: "Como é que o Avaliador Online gera novas leads?",
      lines: [
        `O Avaliador Online gera novas leads ao permitir que potenciais clientes que visitam o
        seu site solicitem uma estimativa de valor gratuita. Assim que preenchem as informações
        necessárias, a ferramenta apresenta ao cliente um intervalo de preços para a propriedade
        inserida. Em paralelo, é criado automaticamente um estudo de mercado para o imóvel, que é
        enviado, juntamente com as informações pessoais do cliente, para um dos seus consultores
        para posterior tratamento.`,
      ],
    },
    {
      title:
        "Qual é o benefício de ter uma página personalizada para cada consultor?",
      lines: [
        `Cada consultor terá a sua própria página personalizada do Avaliador Online automaticamente,
        para que cada um a possa promover individualmente. Isto permite-lhes gerar mais leads e ter
        mais controlo sobre as leads que recebem. Para além disso, cria também um efeito de sinergia
        com a página principal do Avaliador Online, pois é mais uma forma da sua agência gerar mais
        leads de potenciais clientes, incentivando os seus consultores a fazê-lo também.`,
      ],
    },
    {
      title: "Posso customizar o Avaliador Online de acordo com a minha marca?",
      lines: [
        `Sim, o Avaliador Online é totalmente personalizável de acordo com a marca e tom de comunicação
        da sua agência, dando-lhe total controlo sobre a aparência do mesmo. Isto inclui a capacidade de
        adicionar o seu logotipo, alterar cores, tom de comunicação e personalizar o layout da página.`,
      ],
    },
    {
      title: "Como é que o Avaliador Online faz a distribuição das leads?",
      lines: [
        `O Avaliador Online permite-lhe acompanhar cada lead e decidir de que forma são distribuídas entre os seus
        consultores. Isto garante que todas as leads sejam tratadas rapidamente e de maneira eficiente,
        sem que nenhuma fique perdida.`,
      ],
    },
    {
      title: "O Avaliador Online é fácil de usar?",
      lines: [
        `O Avaliador Online é user-friendly e fácil de usar. Foi projetado para ser simples de configurar
        e integrar em qualquer site existente. Para além disso, a nossa equipa está inteiramente disponível
        para fornecer todo o suporte e formação necessários de maneira a garantir uma correta implementação
        e utilização da mesma.`,
      ],
    },
  ],
  en: [
    {
      title: "What is the Digital Valuer tool?",
      lines: [
        `The Digital Valuer is a tool that can be integrated into your website to
      generate new leads. It automates many processes in lead handling, such as
      the creation of the property valuation report that agents need to do before
      contacting potential customers. It is also fully customizable to match your
      business brand, giving you complete control over the look and feel of the tool.`,
      ],
    },
    {
      title: "How does the Digital Valuer generate new leads?",
      lines: [
        `The Digital Valuer generates new leads by allowing website visitors to request
      a free property valuation report. Once the visitor fills out the required information,
      the tool shows the visitor a price interval for the property. It also automatically
      generates a property valuation report and sends it, together with the lead information,
      to the one of your agents or businesses for follow-up.`,
      ],
    },
    {
      title:
        "What is the benefit of having each agent have their own custom version of the digital valuer?",
      lines: [
        `Each agent will have their own custom version of the digital valuer automatically,
      so that each one of them can promote their own page individually. This allows them to
      generate more leads and have more control over the leads they receive. This also creates a
      synergy effect with the main digital valuer page, as it is another way for your business to
      generate more leads and incentivize your agents to do so too.`,
      ],
    },
    {
      title: "Can I customize the Digital Valuer to match my brand?",
      lines: [
        `Yes, the Digital Valuer is fully customizable to match your business brand, giving complete
      control over the look and feel of the tool. This includes the ability to add logos, change
      colors, and customize the layout.`,
      ],
    },
    {
      title: "How does the Digital Valuer handle lead distribution?",
      lines: [
        `The Digital Valuer allows your business to keep track of every lead and decide how they are
      distributed among all agents. This ensures that leads are handled in a timely and efficient
      manner, and that no leads are missed.`,
      ],
    },
    {
      title: "Is the Digital Valuer tool easy to use?",
      lines: [
        `The Digital Valuer tool is user-friendly and easy to use. It is designed to be simple to set
      up and integrate into any existing website. Training and support is also provided to ensure
      smooth implementation and usage.`,
      ],
    },
  ],
  es: [
    {
      title: "¿Qué es la herramienta Valuador Digital?",
      lines: [
        "La herramienta Valuador Digital es una herramienta que puede ser integrada en su sitio web para generar nuevos clientes potenciales. Automatiza muchos procesos en el manejo de clientes potenciales, como la creación del informe de valoración de propiedades que los agentes necesitan hacer antes de contactar a los clientes potenciales. También es completamente personalizable para adaptarse a su marca comercial, dándole un control completo sobre el aspecto y la apariencia de la herramienta.",
      ],
    },
    {
      title: "¿Cómo genera Valuador Digital nuevos clientes potenciales?",
      lines: [
        "Valuador Digital genera nuevos clientes potenciales permitiendo a los visitantes del sitio web solicitar un informe gratuito de valoración de propiedades. Una vez que el visitante rellena la información requerida, la herramienta muestra al visitante un intervalo de precios para la propiedad. También genera automáticamente un informe de valoración de propiedades y lo envía junto con la información del cliente potencial a uno de sus agentes o negocios para su seguimiento.",
      ],
    },
    {
      title:
        "¿Cuál es la ventaja de tener cada agente su propia versión personalizada del valuador digital?",
      lines: [
        "Cada agente tendrá su propia versión personalizada del valuador digital automáticamente, de manera que cada uno de ellos pueda promocionar su propia página individualmente. Esto les permite generar más clientes potenciales y tener más control sobre los clientes potenciales que reciben. Esto también crea un efecto sinergia con la página principal del valuador digital, ya que es otra manera de generar más clientes potenciales y motivar a sus agentes a hacer lo mismo.",
      ],
    },
    {
      title: "¿Puedo personalizar Valuador Digital para adaptarlo a mi marca?",
      lines: [
        "Sí, Valuador Digital es completamente personalizable para adaptarse a su marca comercial, dando un control completo sobre el aspecto y la apariencia de la herramienta. Esto incluye la capacidad de agregar logos, cambiar los colores y personalizar el diseño.",
      ],
    },
    {
      title: "¿Cómo maneja el Valuador Digital la distribución de los leads?",
      lines: [
        "El Valuador Digital permite a su negocio hacer un seguimiento de cada lead y decidir cómo se distribuyen entre todos los agentes. Esto asegura que los leads se manejen de manera oportuna y eficiente, y que no se pierden leads.",
      ],
    },
    {
      title: "¿Es fácil de usar la herramienta Valuador Digital?",
      lines: [
        "La herramienta Valuador Digital es amigable para el usuario y fácil de usar. Está diseñada para ser sencilla de configurar e integrar en cualquier sitio web existente. También se brinda capacitación y soporte para garantizar una implementación y uso suave.",
      ],
    },
  ],
};

export const REPORT = {
  pt: {
    tryAgain: "Por favor volte a tentar mais tarde.",
  },
  en: {
    tryAgain: "Please try again later.",
  },
  es: {
    tryAgain: "Por favor vuelva a intentarlo más tarde.",
  },
};
