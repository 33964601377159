import React from "react";
import { Provider } from "react-redux";
import ContactModule from "./src/components/ContactModule/ContactModule";
import store from "./src/redux/store";
import UmtTags from "./src/components/UmtTags";

export default ({ element }) => {
  return (
    <Provider store={store}>
      {element}
      <ContactModule />
      <UmtTags />
    </Provider>
  );
};
