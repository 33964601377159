exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-avms-js": () => import("./../../../src/pages/avms.js" /* webpackChunkName: "component---src-pages-avms-js" */),
  "component---src-pages-c-21-js": () => import("./../../../src/pages/c21.js" /* webpackChunkName: "component---src-pages-c-21-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-digital-valuer-js": () => import("./../../../src/pages/digital-valuer.js" /* webpackChunkName: "component---src-pages-digital-valuer-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-insights-js": () => import("./../../../src/pages/insights.js" /* webpackChunkName: "component---src-pages-insights-js" */),
  "component---src-pages-metasearch-js": () => import("./../../../src/pages/metasearch.js" /* webpackChunkName: "component---src-pages-metasearch-js" */),
  "component---src-pages-observatory-js": () => import("./../../../src/pages/observatory.js" /* webpackChunkName: "component---src-pages-observatory-js" */),
  "component---src-pages-opportunity-js": () => import("./../../../src/pages/opportunity.js" /* webpackChunkName: "component---src-pages-opportunity-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-price-index-js": () => import("./../../../src/pages/price-index.js" /* webpackChunkName: "component---src-pages-price-index-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-prospect-collection-collection-id-js": () => import("./../../../src/pages/prospect-collection/[...collectionID].js" /* webpackChunkName: "component---src-pages-prospect-collection-collection-id-js" */),
  "component---src-pages-prospect-prospect-id-js": () => import("./../../../src/pages/prospect/[...prospectID].js" /* webpackChunkName: "component---src-pages-prospect-prospect-id-js" */),
  "component---src-pages-report-valuation-id-js": () => import("./../../../src/pages/report/[...valuationID].js" /* webpackChunkName: "component---src-pages-report-valuation-id-js" */),
  "component---src-pages-risk-js": () => import("./../../../src/pages/risk.js" /* webpackChunkName: "component---src-pages-risk-js" */),
  "component---src-pages-subscribe-js": () => import("./../../../src/pages/subscribe.js" /* webpackChunkName: "component---src-pages-subscribe-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-use-cases-js": () => import("./../../../src/pages/use-cases.js" /* webpackChunkName: "component---src-pages-use-cases-js" */),
  "component---src-pages-valuations-js": () => import("./../../../src/pages/valuations.js" /* webpackChunkName: "component---src-pages-valuations-js" */),
  "component---src-pages-website-js": () => import("./../../../src/pages/website.js" /* webpackChunkName: "component---src-pages-website-js" */)
}

