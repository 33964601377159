import React, { useState, useEffect, Fragment } from "react";
import { triggerContact } from "../../redux/appReducer";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import { CONTACT_MODULE } from "../../intl/copyright";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { alfredoAPIRequester } from "../../exporters";
import classNames from "classnames";

const ContactModule = () => {
  const { lang, openContact } = useSelector((state) => state.appReducer);
  const copy = CONTACT_MODULE[lang];

  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch(triggerContact());
  };

  const initFields = {
    name: {
      error: false,
      helperText: null,
      id: "name",
      required: true,
    },
    company: {
      error: false,
      helperText: null,
      id: "company",
      required: false,
    },
    email: {
      error: false,
      helperText: null,
      id: "email",
      required: true,
    },
    message: {
      error: false,
      helperText: null,
      id: "message",
      required: true,
      multiline: true,
    },
  };

  const initStatus = {
    submited: false,
    loading: false,
  };

  const [fields, setFields] = useState(initFields);
  const [status, setStatus] = useState(initStatus);

  useEffect(() => {
    if (openContact) {
      setFields(initFields);
      setStatus(initStatus);
    }
  }, [openContact]);

  const checkField = (id, value, field) => {
    let helperText = "";
    let error = false;
    if (field.required && (!value || value.trim() === "")) {
      error = true;
      helperText = copy.required;
    } else if (id === "email") {
      const REGEX_EMAIL =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!REGEX_EMAIL.test(value)) {
        error = true;
        helperText = copy.invalidEmail;
      }
    }
    return {
      value: value,
      error: error,
      helperText: helperText,
    };
  };

  const checkContactForm = (fields) => {
    let errors = [];
    let updateFields = { ...fields };

    Object.keys(fields).forEach((field) => {
      let result = checkField(field, fields[field].value, fields[field]);

      updateFields[field] = {
        ...updateFields[field],
        ...result,
      };

      if (result.error) errors.push(1);
    });

    setFields(updateFields);
    return errors;
  };

  const handleSubmit = () => {
    const errors = checkContactForm(fields);
    if (errors.length !== 0) return;

    setStatus({
      loading: true,
      submited: false,
    });

    alfredoAPIRequester
      .post({
        urlPath: `website/contact_form`,
        params: {
          company: fields.company.value || "",
          message: fields.message.value,
          email: fields.email.value,
          name: fields.name.value,
        },
      })
      .then((response) => {
        setStatus({
          loading: false,
          submited: true,
        });
      })
      .catch((err) =>
        setStatus({
          loading: false,
        })
      );
  };

  const handleChange = (id) => (e) => {
    setFields({
      ...fields,
      [id]: {
        ...fields[id],
        value: e.target.value,
        helperText: "",
        error: false,
      },
    });
  };

  const handleBlur = (id) => () => {
    if (openContact) return;
    const validated = checkField(id, fields[id].value, fields[id]);

    setFields({
      ...fields,
      [id]: {
        ...fields[id],
        ...validated,
      },
    });
  };

  const classBtn = classNames(
    "px-4 py-2  text-base font-medium ",
    "border  border-transparent",
    "items-center rounded-xl",
    "text-white",
    "bg-neutral-900 w-full",
    "hover:bg-neutral-800"
  );

  const renderError = (f) => {
    if (!f.error) return;
    return (
      <p
        className={classNames("text-xs ", {
          "text-red-500": f.error,
        })}
      >
        {f.helperText}
      </p>
    );
  };

  return (
    <Transition appear show={openContact} as={Fragment}>
      <Dialog as="div" className="relative z-40" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-70" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-xl font-medium leading-8 text-gray-900 "
                >
                  <div className="flex items-center">
                    <div className="flex-grow">{copy.title}</div>
                    <div className="cursor-pointer" onClick={closeModal}>
                      <XMarkIcon className="w-6 h-6" />
                    </div>
                  </div>
                </Dialog.Title>
                <div className="mt-2 mb-5">
                  {status.submited && (
                    <p className="text-sm text-gray-500">{copy.subTitle}</p>
                  )}
                </div>
                {status.submited ? (
                  <div>
                    <div className="text-5xl">{copy.thanks}!</div>
                    <div className="text-xl pt-2">{copy.success}</div>
                  </div>
                ) : (
                  <div>
                    <form className="w-full max-w-lg">
                      <div className="flex flex-wrap -mx-3 mb-2">
                        <div className="w-full md:w-1/2 px-3">
                          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                            {copy.name}
                          </label>
                          <input
                            onChange={handleChange("name")}
                            onBlur={handleBlur("name")}
                            className="appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-gray-500"
                            type="text"
                          />
                          {renderError(fields.name)}
                        </div>
                        <div className="w-full md:w-1/2 px-3">
                          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                            {copy.company}
                          </label>
                          <input
                            onChange={handleChange("company")}
                            onBlur={handleBlur("company")}
                            className="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none  focus:border-gray-500"
                            type="text"
                          />
                          {renderError(fields.company)}
                        </div>
                      </div>
                      <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full px-3">
                          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                            {copy.email}
                          </label>
                          <input
                            onChange={handleChange("email")}
                            onBlur={handleBlur("email")}
                            className="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none  focus:border-gray-500"
                            type="email"
                          />
                          {renderError(fields.email)}
                        </div>
                      </div>
                      <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full px-3">
                          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                            {copy.message}
                          </label>
                          <textarea
                            disabled={status.loading}
                            onChange={handleChange("message")}
                            onBlur={handleBlur("message")}
                            className="no-resize appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none  focus:border-gray-500 h-48 resize-none"
                          ></textarea>
                          {renderError(fields.message)}
                        </div>
                      </div>
                    </form>

                    <div className="mt-4">
                      {status.loading ? (
                        <div className="flex justify-center">
                          <div className="w-10 h-10 border-4 border-blue-400 border-dotted rounded-full animate-spin"></div>
                        </div>
                      ) : (
                        <button
                          disabled={status.loading}
                          onClick={handleSubmit}
                          className={classBtn}
                          type="button"
                        >
                          {copy.submit}
                        </button>
                      )}
                    </div>
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ContactModule;
